import Vue from 'vue';

export default {
  getVideos: async () => Vue.http.get('general/studios-playlist')
    .then((res) => res.json()),

  insertVideo: async (model) => Vue.http.post('general/studios-playlist', model)
    .then((res) => res.json()),

  updateVideo: async (model) => Vue.http.put(`general/studios-playlist/${model.id}`, model)
    .then((res) => res.json()),

  deleteVideo: async (videoId) => Vue.http.delete(`general/studios-playlist/${videoId}/`)
    .then((res) => res.json()),

  uploadPicture: async (model) => Vue.http.post('general/studios-playlist/upload/', model)
    .then((res) => res.json()),

};
