<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.'+type) }}
        </h3>
      </b-col>
    </b-row>

    <form @submit.prevent="onSubmit" v-if="loaded">
      <b-row>
        <b-col class="ml-3 mb-3 p-4 bg-white border border-secondary rounded-lg">

          <b-form-group :label="$t('inputs.type')">
            <b-form-radio-group name="radio-type" required v-model="form.type">
              <b-form-radio value="youtube">Youtube</b-form-radio>
              <b-form-radio value="vimeo">Vimeo</b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <b-input-group
            :prepend="$t('inputs.videoId')"
            class="mb-3">
            <b-form-input
              v-model="form.videoId"
              maxlength="100"
              type="text"
              required
              @blur="loadEmbed"
            />
            <b-input-group-append is-text v-b-tooltip.hover.html="getTooltip()">
              <b-icon icon="exclamation-circle" />
            </b-input-group-append>
          </b-input-group>

          <b-form-group :label="$t('inputs.thumb')" class="mb-3">
            <ImageUploader
              minWidth="480"
              minHeight="360"
              v-model="upload"
            ></ImageUploader>
          </b-form-group>

          <b-input-group :prepend="$t('inputs.titleEN')" class="mb-3">
            <b-form-input
              v-model="form.nameEN"
              maxlength="100"
              type="text"
              required
            />
          </b-input-group>
          <b-input-group :prepend="$t('inputs.titlePT')" class="mb-3">
            <b-form-input
              v-model="form.namePT"
              maxlength="100"
              type="text"
              required
            />
          </b-input-group>

          <TextArea
          :label="$t('inputs.descriptionPT')"
          v-model="form.descriptionPT"
          maxlength="1000" />

          <TextArea
          :label="$t('inputs.descriptionEN')"
          v-model="form.descriptionEN"
          maxlength="1000" />

          <b-button
            :disabled="loading"
            class="mr-2"
            type="submit"
            variant="outline-primary"
            @click="cancel"
            >{{ $t("buttons.cancel") }}</b-button
          >
          <b-button :disabled="loading" type="submit" variant="success">{{
            $t("buttons.save")
          }}</b-button>
        </b-col>

        <b-col lg="6" sm="12" >
          <img :src="upload.preview || thumb" class="preview mb-1" />
        </b-col>
      </b-row>

      <b-table
        class="mt-3"
        show-empty
        responsive
        bordered
        striped
        hover
        :busy="loading"
        :items="model.videos"
        :fields="fields"
        :emptyText="$t('messages.emptyTable')"
        :emptyFilteredText="$t('messages.emptyFilteredTable')"
      >
        <template v-slot:table-busy>
          <div
            class="text-center text-danger my-2 align-items-center d-flex flex-column"
          >
            <b-spinner
              class="align-middle flex-direction-column mb-3"
            ></b-spinner>
            <strong>{{ $t("messages.loading") }}...</strong>
          </div>
        </template>

        <template v-slot:cell(thumbnail)="data">
          <img :src="data.item.thumbnail" class="thumb" />
        </template>

        <template v-slot:cell(actions)="data">
          <p class="text-center m-0">

            <b-button
              size="sm"
              class="ml-1"
              variant="outline-primary"
              @click="edit(data.item.id)"
            >
              <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              variant="danger"
              @click="deleteVideo(data.item.id)"
            >
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </b-button>

            <span class="mx-1" />
          </p>
        </template>
      </b-table>
    </form>

    <b-row v-else>
      <b-col cols="12">
        <h5 class="text-center mt-4">{{ $t("messages.loading") }}...</h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import Studios from '../../../../services/Studios';
import ImageUploader from '../../../../components/organisms/ImageUploader.vue';
import TextArea from '../../../../components/organisms/TextArea.vue';
import Toast from '../../../../assets/js/toast';

export default {
  props: ['type'],
  components: {
    ImageUploader,
    TextArea,
  },
  data() {
    const data = {
      isEdit: false,
      loaded: false,
      loading: true,
      thumb: null,
      embed: '',
      upload: {
        file: null,
        preview: null,
        blob: null,
      },
      form: {
        type: 'youtube',
      },

      model: {},

      fields: [
        {
          key: 'thumbnail',
          label: '',
          sortable: false,
        },
        {
          key: 'nameEN',
          label: `${this.$t('inputs.titleEN')}`,
          sortable: false,
        },
        {
          key: 'namePT',
          label: `${this.$t('inputs.titlePT')}`,
          sortable: false,
        },
        { key: 'actions', label: this.$t('inputs.action') },
      ],
    };

    return data;
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    loadEmbed() {
      this.thumb = null;
      if (this.form.type === 'youtube') {
        this.embed = `https://www.youtube.com/embed/${this.form.videoId}`;
        this.thumb = `https://img.youtube.com/vi/${this.form.videoId}/0.jpg`;
      } else {
        this.embed = `https://player.vimeo.com/video/${this.form.videoId}`;
        Vue.http.jsonp(`//vimeo.com/api/v2/video/${this.form.videoId}.json`)
          .then((res) => res.json())
          .then((res) => {
            if (res && res.length > 0) {
              this.thumb = res[0].thumbnail_large;
            } else {
              this.thumb = null;
            }
          });
      }
    },
    getTooltip() {
      if (this.form.type === 'vimeo') return '<small>https://vimeo.com/</small><strong>76979871</strong>';
      return '<small>https://www.youtube.com/watch?v=</small><strong>eyGw85h8nGs</strong>';
    },
    cancel(e) {
      if (e) e.preventDefault();
      this.form.videoId = null;
      this.form.namePT = null;
      this.form.nameEN = null;
      this.form.descriptionPT = null;
      this.form.descriptionEN = null;
      this.form.id = null;
      this.form.order = null;
      this.upload = {
        file: null,
        preview: null,
        blob: null,
      };
      this.thumb = null;
      this.embed = null;
    },
    async deleteVideo(id) {
      await Studios.deleteVideo(id, this.model.id);
      await this.onLoadUser();
    },
    async edit(id) {
      this.form = { ...this.model.videos.find((i) => i.id === id) };
      this.loadEmbed();
      if (this.form.thumbnail) {
        this.upload.preview = this.form.thumbnail;
      }
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    async onSubmit() {
      try {
        this.loading = true;

        const fd = new FormData();
        let objPic;
        if (this.upload.file) {
          fd.append('file', this.upload.blob, this.upload.file.name);
          objPic = await Studios.uploadPicture(
            fd,
          );
          this.form.thumbnail = objPic.filename;
        } else if (this.thumb) {
          this.form.thumbnail = this.thumb;
        }

        if (!this.form.id) await Studios.insertVideo(this.form, this.model.id);
        else await Studios.updateVideo(this.form, this.model.id);
        await this.onLoadUser();
        this.cancel();
        this.loading = false;
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          scope.setExtra('form', JSON.stringify(this.form, undefined, 2));
          Sentry.captureException(e);
        });

        Toast.error(this, e);
        this.loading = false;
      }
    },
    async onLoad() {
      this.loading = true;

      await this.onLoadUser();

      this.loading = false;
      this.loaded = true;
    },

    async onLoadUser() {
      try {
        this.isEdit = true;

        const videos = await Studios.getVideos();

        this.model = {
          videos: videos
            .map((v) => ({
              ...v,
              thumbnail: v.thumbnail.indexOf('http') === 0
                ? v.thumbnail
                : `${Vue.prototype.VUE_APP_S3_URL}/studios-playlist/${v.thumbnail}`,
            })),
        };
      } catch (e) {
        Sentry.captureException(e);

        Toast.error(this, e);
        console.log('Falha ao carregar!', e);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.footer-buttons
  button
    min-width: 100px
.thumb
  max-height: 40px
.preview
  max-width: 100%;
.iframe
  width: 100%
  height: 380px
</style>
